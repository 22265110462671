import React from 'react';
import RedirectTo from "../../components/shared/RedirectTo/RedirectTo";
import {careersEnabled, getLink, getRoutes} from "../../components/shared/routes";
import OfferPage from "../../components/shared/OfferPage/OfferPage";
import ConstructionTeamLeaderLogo from "../../images/Careers/deeplai_career_construction_team_leader.svg";

const CareersConstructionTeamLeader = () => {
    const routes = getRoutes();

    if (!careersEnabled.list) {
        return <RedirectTo/>;
    }
    if (!careersEnabled.constructionTeamLeader) {
        return <RedirectTo url={getLink([routes.Careers])}/>;
    }

    return (

        <OfferPage
            image={ConstructionTeamLeaderLogo}
            imageAlt={'deeplai career "Construction Team Leader'}
            name={routes.CareersConstructionTeamLeader.pageTitle}
            scopeOfDuties={<>
                <p>Lead the company R&D team</p>
                <p>Supervision of the construction and production of equipment prototypes</p>
                <p>Managing the design team - responsibility for the results of the team's work</p>
                <p>Active engagement and participation in development work, elaboration of new technological solutions
                    and their testing</p>
                <p>Overseeing the quality and timeliness of executed projects</p>
                <p>Development of executive, order, assembly and technical documentation</p>
                <p>Execution of the set KPI’s, reporting, escalating</p>
                <p>Supervision of the implementation of built prototypes into production phase</p>
                <p>Collaboration with other company departments</p>
            </>}
            ourRequirements={<>
                <p>Higher technical education, majoring in mechanical engineering or related field (mechanics,
                    mechatronics, etc.)</p>
                <p>High self-reliance and commitment to the tasks undertaken at each stage of the work</p>
                <p>Practical knowledge in the area of mechanics, mechatronic, equipment construction</p>
                <p>Ability to design in 3D and 2D CAD environment</p>
                <p>Knowledge of the principles of technical drawing and design</p>
                <p>Knowledge of issues in the field of mechanics, pneumatics, electronics</p>
                <p>Leadership, engaging team members in delivering tasks</p>
                <p>Driving license cat. B, readiness for occasional trips to contractors</p>
                <p>English level B1</p>
            </>}
            whatYouCanExpectFromUs={<>
                <p>Stationary work at office in Lublin</p>
                <p>Friendly atmosphere, daily cooperation with open minded people</p>
                <p>Various, interesting projects in cutting-edge technologies</p>
                <p>Real personal impact on implemented projects</p>
                <p>Renumeration package adequate to your experience</p>
                <p>Training tailored to your needs and career goals</p>
                <p>Flexible working hours</p>
                <p>Private medical care, Multisport card</p>
            </>}
        />
    );
};

export default CareersConstructionTeamLeader;
